<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>{{ $t('configuracaoSistema') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('qtdeDiasExpiracaoCotacaoDistribuidor') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeDiasExpiracaoCotacaoDistribuidor" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('qtdeDiasExpiracaoCotacaoUsuarioInterno') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeDiasExpiracaoCotacaoInterno" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('landedCost') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.landedCost"
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="$t('configuracaoSolicitacaoCadastroProduto')" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>{{ $t('informeEmailReceberSolicitacao') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.emailSolicitacaoCadastroProduto" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>{{ $t('tituloEmail') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.tituloSolicitacaoCadastroProduto" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('descricaoCorpoEmail') }}</label>
                    <v-textarea
                        v-model="configuracaoSistemaRequest.descricaoSolicitacaoCadastroProduto"
                        single-line
                        rows="4"
                        row-height="30"
                        outlined
                    >
                    </v-textarea>
                    <p style="font-weight: normal !important; margin-top: -20px; font-size: 10px;">
                        {{ $t('parametroDisponivelConfiguracao') }}
                        <br />
                        <br />
                        ${nome-solicitante}: {{ $t('nomeSolicitante') }}
                        <br />
                        ${codigo-produto}: {{ $t('codigoProdutoBuscaProduto') }}
                    </p>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="$t('certificacaoEX')" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('valor') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.valorCertificacaoEX"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('qtdeMaxima') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.qtdeMaximaCertificacaoEX" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="peças"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('prazoReincidencia') }}</label>
                    <v-text-field
                        v-model="configuracaoSistemaRequest.prazoReincidenciaCertificacaoEX" 
                        :rules=[validations.required]
                        v-mask="'#####'"
                        suffix="dias"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="configuracaoSistemaRequest"
                :loading="loading"
                :showButtons="{
                    cancel: false,
                    save: true
                }"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons
        },

        data: () => ({

            id: 1,

            validForm: true,

            configuracaoSistemaRequest: {
                id: 0,
                qtdeDiasExpiracaoCotacaoDistribuidor: 0,
                qtdeDiasExpiracaoCotacaoInterno: 0,
                emailSolicitacaoCadastroProduto: '',
                tituloSolicitacaoCadastroProduto: '',
                descricaoSolicitacaoCadastroProduto: '',
                valorCertificacaoEX: 'R$ 0,00',
                qtdeMaximaCertificacaoEX: 0,
                prazoReincidenciaCertificacaoEX: 0,
                landedCost: 0
            },

            moneyFormat: {
                prefix: `R$ `,
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            loading: false,
            
            validations: {
                required: required,
            },
        }),

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {
                    const response = await this.$store.dispatch("configuracaoSistemaModule/GetById", this.id);

                    if (response.success) {
                        this.configuracaoSistemaRequest = response.result;
                        this.configuracaoSistemaRequest.valorCertificacaoEX = `R$ ${this.configuracaoSistemaRequest.valorCertificacaoEXFormatted}`;
                        this.configuracaoSistemaRequest.landedCost = this.configuracaoSistemaRequest.landedCostFormatted;
                    }
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.configuracaoSistemaRequest.id = this.id;
                    this.configuracaoSistemaRequest.valorCertificacaoEX = formaterDecimalBRServer(this.configuracaoSistemaRequest.valorCertificacaoEX);
                    this.configuracaoSistemaRequest.landedCost = formaterDecimalBRServer(this.configuracaoSistemaRequest.landedCost);

                    const result = await this.$store.dispatch("configuracaoSistemaModule/CreateUpdate", this.configuracaoSistemaRequest);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.getRegister();
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>



<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>